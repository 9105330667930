import {Component, OnInit} from '@angular/core';
import {PageResponse} from "../api/page-response";
import {Unit} from "../api/unit";
import {LazyLoadEvent, MessageService} from "primeng/api";
import {PageRequest} from "../api/page-request";
import {UnitService} from "../services/unit.service";
import {DialogService} from "primeng/dynamicdialog";
import {CreateUnitComponent} from "../create-unit/create-unit.component";
import {UpdateUnitComponent} from "../update-unit/update-unit.component";
import {ToastService} from "../services/toast.service";
import {environment} from "../../environments/environment";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'app-view-units',
  templateUrl: './view-units.component.html',
  styleUrl: './view-units.component.css',
  providers: [DialogService, MessageService]
})
export class ViewUnitsComponent implements OnInit{

  unitsPage: PageResponse<Unit>;

  productUrl = '';

  sortField: any;


  private lastTableLazyLoadEvent: LazyLoadEvent;

  cols: any[] = [];

  constructor(private unitService: UnitService,
              private dialogService: DialogService,
              private toastService: ToastService,
              private clipboard: Clipboard,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.unitsPage = new PageResponse<Unit>();
    this.productUrl = environment.productUrl;
    this.sortField = "name";
  }

  loadUnits(event: any) {
    this.lastTableLazyLoadEvent = event;
    this.unitService.getUnits(new PageRequest(event)).subscribe(response => {
      this.unitsPage = response;
    });
  }

  openNew() {
    let ref = this.dialogService.open(CreateUnitComponent, {
      header: "Adaugare unitate",
      dismissableMask: true,
      width: "70%",
      contentStyle: {'overflow': 'hidden'}
    });

    ref.onClose.subscribe(a => {
      this.loadUnits(this.lastTableLazyLoadEvent);
    });
  }

  editUnit(ru: Unit) {
    let ref = this.dialogService.open(UpdateUnitComponent, {
      data: {
        unit: ru
      },
      header: "Editare unitate",
      dismissableMask: true,
      width: "70%",
      contentStyle: {'overflow': 'hidden'}
    });

    ref.onClose.subscribe(a => {
      this.loadUnits(this.lastTableLazyLoadEvent);
    })
  }

  deleteUnit(ru: Unit) {
    this.unitService.deleteUnit(ru.id).subscribe({
      next: () => {
        this.toastService.setToast({
          'severity': 'success',
          'summary': 'Succes',
          'detail': 'Unitatea a fost stearsa cu succes!'
        });
        this.loadUnits(this.lastTableLazyLoadEvent);
      },
      error: () => {
        this.toastService.setToast({
          'severity': 'error',
          'summary': 'Eroare',
          'detail': 'Unitatea nu a putut fi stearsa!'
        });
      }
    });
  }

  copyToClipboard(input: any) {
    this.clipboard.copy(input);
    this.messageService.add({ severity: 'success', summary: 'Succes', detail: 'Link-ul a fost copiat cu succes!' });
  }

}
