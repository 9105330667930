<form [formGroup]="userForm" (ngSubmit)="submitForm()">
  <div class="formgrid grid">
    <div class="field col-12 md:col-6">
      <label for="email">Email:</label>
      <input pInputText type="text" id="email" formControlName="email" required class="w-full">
    </div>
    <div class="field col-12 md:col-6">
      <label for="nrTel">Telefon:</label>
      <input pInputText type="text" id="nrTel" formControlName="phoneNumber" required class="w-full">
    </div>
    <div class="field col-12 md:col-6">
      <label for="persoanaContact">Persoana Contact:</label>
      <input pInputText type="text" id="persoanaContact" formControlName="contactPerson" required class="w-full">
    </div>

    <div class="field col-12">
<!--      <input pInputText type="text" id="persoanaContact" formControlName="contactPerson" required class="w-full">-->
      <p-button type="submit" [disabled]="userForm.invalid">Salvează</p-button>
    </div>
  </div>
</form>
