import {Component, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators
} from "@angular/forms";
import {Subscription} from "rxjs";
import {AddressService} from "../services/address.service";
import {AutoCompleteCompleteEvent, AutoCompleteSelectEvent} from "primeng/autocomplete";
import {MessageService} from "primeng/api";
import {Clipboard} from '@angular/cdk/clipboard'
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-unit-form',
  templateUrl: './unit-form.component.html',
  styleUrl: './unit-form.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UnitFormComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: UnitFormComponent
    },
    MessageService
  ]
})
export class UnitFormComponent implements OnInit, ControlValueAccessor, OnDestroy, Validator {

  public link = '';

  form = this.fb.group({
    name: [null, [Validators.required, noSymbols]],
    address: [null, [Validators.required]],
    location: [null, [Validators.required]],

    id: [null],
    createdAt: [null],
    updatedAt: [null],
  });

  get name() {
    return this.form.get('name') as any;
  }

  get address() {
    return this.form.get('address');
  }

  get location() {
    return this.form.get('location');
  }


  onTouched: Function = () => {
  };

  onChangeSubs: Subscription[] = [];
  addressOptions: any;

  ngOnInit(): void {
    this.link = '.../';

    this.name.valueChanges.subscribe((n: any) => {
      if (n) {
        this.link = ".../" + n.toLowerCase().replaceAll(" ", "-");
      }
    });
  }

  constructor(private fb: FormBuilder,
              private addressService: AddressService,
              private messageService: MessageService,
              private clipboard: Clipboard) {

  }

  ngOnDestroy(): void {
    for (let sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  registerOnChange(onChange: any) {
    const sub = this.form.valueChanges.subscribe(onChange);
    this.onChangeSubs.push(sub);
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  writeValue(value: any) {
    if (value) {
      this.form.patchValue(value, {emitEvent: false});
    }
  }

  validate(control: AbstractControl) {
    if (this.form.valid) {
      return null;
    }

    let errors: any = {};

    for (let controlsKey in this.form.controls) {
      //@ts-ignore
      const controlErrors = this.form.controls[controlsKey].errors;
      if (controlErrors) {
        errors[controlsKey] = controlErrors;
      }
    }

    return errors;
  }


  search($event: AutoCompleteCompleteEvent) {
    this.addressService.search($event.query).subscribe((res: any[]) => {
      if (res?.length > 0) {
        this.addressOptions = res;
      }
    })
  }

  onAddressSelect($event: AutoCompleteSelectEvent) {
    console.log($event);
    let a = {"lat": $event.value.lat, "lng": $event.value.lon};

    // @ts-ignore
    this.location.patchValue(a)
  }

  copyToClipboard(input: any) {
    this.clipboard.copy(environment.productUrl + this.name.value.toLowerCase().replaceAll(" ", "-"));
    this.messageService.add({ severity: 'success', summary: 'Succes', detail: 'Link-ul a fost copiat cu succes!' });
  }
}

export function noSymbols(control: AbstractControl) {
  if (!/^[^`~!@#$%\^&*()_+={}|[\]\\:';"<>?,./]*$/.test(control.value)) {
    return {symbols: true};
  }
  return null;
}
