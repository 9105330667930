<form [formGroup]="form">
  <div class="p-fluid">
    <div class="field">
      <label htmlFor="name">Nume</label>
      <input pInputText id="name" type="text" autocomplete="off" formControlName="name"/>
      <app-validation-error [control]="name"></app-validation-error>
    </div>

    <div class="field">
      <label for="name">Link:</label>
      <p-inputGroup>
        <button type="button" pButton size="small" icon="pi pi-copy" class="p-button-success" (click)="copyToClipboard($event)" ></button>
        <input pInputText type="text" id="link" [value]="link" [disabled]="true" required class="w-full">
      </p-inputGroup>


    </div>

    <div class="field">
      <label htmlFor="name">Adresa</label>

      <p-autoComplete formControlName="address"
                      [suggestions]="addressOptions"
                      (completeMethod)="search($event)"
                      field="displayName"
                      (onSelect)="onAddressSelect($event)"
      >

      </p-autoComplete>


      <app-validation-error [control]="address"></app-validation-error>
    </div>

    <div class="field">
      <app-leaflet-map formControlName="location"></app-leaflet-map>
    </div>

  </div>
</form>
