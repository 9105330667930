import {Component, ElementRef, ViewChild} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {LayoutService} from "./services/app.layout.service";
import {Router} from "@angular/router";
import {DialogService} from "primeng/dynamicdialog";
import {UserComponent} from "./user/user.component";
import {ToastService} from "./services/toast.service";

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  providers: [DialogService]
})
export class AppTopBarComponent {

  items!: MenuItem[];

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  constructor(public layoutService: LayoutService,
              private router: Router,
              private dialogService: DialogService,
              private toastService: ToastService) {
  }

  openUserProfileDialog($event: MouseEvent) {
    let ref = this.dialogService.open(UserComponent, {
      header: "Administrare date hotelier",
      dismissableMask: true,
      width: "70%",
      contentStyle: {'overflow': 'hidden'}
    });
  }
}
