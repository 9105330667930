import {LazyLoadEvent} from "primeng/api";
// @ts-nocheck
export class PageRequest {
    page: number;
    size: number;
    sortField?: string;
    sortOrder?: number;
    filtersMap: any;

    // @ts-ignore
    constructor(lazyLoadEvent: LazyLoadEvent) {
        let offset = lazyLoadEvent.first ? lazyLoadEvent.first : 0;
        this.size = lazyLoadEvent.rows ? lazyLoadEvent.rows : 10;
        this.sortField = lazyLoadEvent.sortField;
        this.sortOrder = lazyLoadEvent.sortOrder;
        this.filtersMap = lazyLoadEvent.filters;

        this.page = offset / this.size;
    }

    // // @ts-ignore
    // constructor(offset: number, size: number) {
    //     this.size = size;
    //
    //     this.page = offset / size;
    // }

}
