import {Location} from "./location";

export class Unit {

  id: string
  createdAt: number
  updatedAt: number

  name: string
  clientUrlKey: string
  address: string

  location: Location

}
