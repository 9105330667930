import {Pageable} from "./pageable";
import {Sort} from "./sort";

export class PageResponse<ContentType> {
    content: Array<ContentType>;
    pageable: Pageable;
    last: boolean;
    totalElements: number;
    totalPages: number;
    size: number;
    sort: Sort;
    first: boolean;
    numberOfElements: number;
    empty: boolean;
}
