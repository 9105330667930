import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from "@angular/forms";

@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrl: './validation-error.component.css'
})
export class ValidationErrorComponent implements OnInit{

  @Input() control: AbstractControl | null = null;

  constructor() { }

  ngOnInit(): void {
  }

}
