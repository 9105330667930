import {Injectable} from '@angular/core';
import {RestService} from "./rest.service";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  public baseUrl = `${environment.apiUrl}/address`


  constructor(private restService: RestService) {
  }

  search(query: String) {
    return this.restService.get(`${this.baseUrl}?query=` + query)
  }

}
