import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {Message} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor() { }

  toastChange: Subject<Object | null> = new Subject<Object | null>();

  setToast(message: Message) {
    this.toastChange.next(message);
  }

  clear() {
    this.toastChange.next(null);
  }
}
