<p-table #dt [value]="unitsPage.content" [columns]="cols" responsiveLayout="scroll" [rows]="10"
         [lazy]="true" (onLazyLoad)="loadUnits($event)" dataKey="id"
         [sortField]="sortField"
         [paginator]="true" [totalRecords]="unitsPage.totalElements" [globalFilterFields]="['name']"
         [rowsPerPageOptions]="[10,75,100]">
  <ng-template pTemplate="caption">
    <div class="flex align-items-center justify-content-between">
      <h3 class="m-0">Listă unitati</h3>

      <span class="p-input-icon-left ml-auto">
        <button pButton pRipple label="Adauga" icon="pi pi-plus" class="p-button-success mr-2"
                (click)="openNew()"></button>
      </span>
    </div>
  </ng-template>

  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="name">Nume
        <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th>Link
      </th>
      <th pSortableColumn="address">Adresa
        <p-sortIcon field="address"></p-sortIcon>
      </th>
      <th></th>
    </tr>
    <tr>
      <th>
        <p-columnFilter #nameFilter (keyup)="nameFilter.applyFilter()" matchMode="contains" [showMenu]="false" type="text" field="name"></p-columnFilter>
      </th>
      <th>

      </th>
      <th>
        <p-columnFilter #addressFilter (keyup)="addressFilter.applyFilter()" matchMode="contains" [showMenu]="false" type="text" field="address"></p-columnFilter>
      </th>
      <th>

      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-ru>
    <tr>
      <td style="min-width:10rem;">
        {{ru.name}}
      </td>
      <td style="min-width:10rem; cursor: pointer" (click)="copyToClipboard(productUrl + ru.clientUrlKey)">
        {{productUrl + ru.clientUrlKey}}
      </td>
      <td style="min-width:10rem;">
        {{ru.address}}
      </td>
      <td>
        <div class="flex">
          <button pButton pRipple icon="pi pi-pencil"
                  class="p-button-rounded p-button-warning mr-2"
                  (click)="editUnit(ru)"></button>
          <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2"
                  (click)="deleteUnit(ru)"></button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-toast></p-toast>
