import {Injectable} from '@angular/core';
import {PageRequest} from "../api/page-request";
import {environment} from "../../environments/environment";
import {RestService} from "./rest.service";
import {PageResponse} from "../api/page-response";
import {Unit} from "../api/unit";

@Injectable({
  providedIn: 'root'
})
export class UnitService {

  public baseUrl = `${environment.apiUrl}/units`

  constructor(private restService: RestService) {

  }

  getUnits(pageRequest: PageRequest) {
    return this.restService.get<PageResponse<Unit>>(this.baseUrl, pageRequest);
  }

  deleteUnit(id: string) {
    console.log(id);
    return this.restService.delete(this.baseUrl + '/' + id);
  }

  upsertUnit(unit: Unit) {
    return this.restService.post<Unit>(this.baseUrl, unit);
  }
}
