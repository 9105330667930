import {Component, OnDestroy, OnInit} from '@angular/core';
import {circle, latLng, LeafletMouseEvent, tileLayer} from "leaflet";
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator
} from "@angular/forms";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: LeafletMapComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: LeafletMapComponent
    },
  ]
})
export class LeafletMapComponent implements OnInit, ControlValueAccessor, OnDestroy, Validator {
  mapOptions: any;
  layer: any;

  map: L.Map;

  onMapReady(map: L.Map) {
    this.map = map;
    this.map.panTo(latLng(this.lat.value, this.lng.value));

  }

  form = this.fb.group({
    lat: [null],
    lng: [null]
  });

  get lat() {
    return this.form.get('lat');
  }

  get lng() {
    return this.form.get('lng');
  }

  constructor(private fb: FormBuilder) {

  }

  onTouched: Function = () => {
  };

  ngOnDestroy() {
    for (let sub of this.onChangeSubs) {
      sub.unsubscribe();
    }
  }

  registerOnChange(onChange: any) {
    const sub = this.form.valueChanges.subscribe(onChange);
    this.onChangeSubs.push(sub);
  }

  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  writeValue(value: any) {
    if (value) {
      this.form.patchValue(value, {emitEvent: false});
      this.layer = circle([value.lat, value.lng], {radius: 50});
      this.center = latLng(value.lat, value.lng);
    }

  }

  validate(control: AbstractControl) {
    if (this.form.valid) {
      return null;
    }

    let errors: any = {};

    for (let controlsKey in this.form.controls) {
      //@ts-ignore
      const controlErrors = this.form.controls[controlsKey].errors;
      if (controlErrors) {
        errors[controlsKey] = controlErrors;
      }
    }

    return errors;
  }

  onChangeSubs: Subscription[] = [];
  center: any;

  ngOnInit(): void {

    this.mapOptions = {
      layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {maxZoom: 18, attribution: '...'})
      ],
      zoom: 15,
      center: latLng(45.9442858, 25.0094303)
    };
  }


  onClick(event: LeafletMouseEvent) {
    this.lat.patchValue(event.latlng.lat);
    this.lng.patchValue(event.latlng.lng);
    this.layer = circle([event.latlng.lat, event.latlng.lng], {radius: 50});
  }
}
