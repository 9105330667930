import { Component } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {UnitService} from "../services/unit.service";
import {DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../services/toast.service";
import {Unit} from "../api/unit";

@Component({
  selector: 'app-create-unit',
  templateUrl: './create-unit.component.html',
  styleUrl: './create-unit.component.css'
})
export class CreateUnitComponent {

  createUnitForm: FormGroup = this.fb.group({
    unit: [new Unit()]
  });

  ngOnInit(): void {
  }

  constructor(private unitService: UnitService,
              private fb: FormBuilder,
              private dialogRef: DynamicDialogRef,
              private toastService: ToastService) {
  }

  get unit() {
    return this.createUnitForm.get('unit');
  }

  createUnit() {
    if (this.createUnitForm.invalid) {
      this.createUnitForm.markAllAsTouched();
      return;
    }

    let unit = this.createUnitForm.value.unit;

    if(unit.address.hasOwnProperty('displayName')) {
      unit.address = unit.address.displayName;
    }

    this.unitService.upsertUnit(unit).subscribe({
      next: () => {
        this.toastService.setToast({
          'severity': 'success',
          'summary': 'Succes',
          'detail': 'Unitatea a fost salvata cu succes!'
        });
        this.dialogRef.close();
      },
      error: () => {
        this.toastService.setToast({
          'severity': 'error',
          'summary': 'Eroare',
          'detail': 'Unitatea nu a putut fi salvata!'
        });
      }
    });
  }
}
