<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
<!--        <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo-dark' : 'logo-white'}}.svg" alt="logo">-->
        <span>LAPLACE</span>
    </a>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <button class="p-link layout-topbar-button">
            <i (click)="openUserProfileDialog($event)" class="pi pi-user"></i>
            <span>Profil</span>
        </button>
    </div>
</div>
