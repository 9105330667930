import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient, HttpParams} from "@angular/common/http";
import {parse} from "@rsql/parser";
import builder from "@rsql/builder";
import {emit} from "@rsql/emitter";

@Injectable({
  providedIn: 'root'
})
export class RestService {

  get httpClient(): HttpClient {
    return this._httpClient;
  }

  constructor(private _httpClient: HttpClient) { }

  getFiltered(url: string, queryParams?: { [k: string]: string | string[] }): Observable<any> {
    return this._httpClient
      .get(url, { params: queryParams })
  }

  //@ts-ignore
  get<ContentType> (url: string, pageRequest: PageRequest = null): Observable<ContentType> {
    let params = new HttpParams();
    if(pageRequest) {
      params = params
        .set("page", pageRequest.page)
        .set("size", pageRequest.size)

      if(pageRequest.sortField) {
        params = params.set("sort", pageRequest.sortField + "," + (pageRequest.sortOrder == 1 ? "asc" : "desc"));
      }

      if(pageRequest.filtersMap) {
        let newExpression = parse("1==1");
        Object.keys(pageRequest.filtersMap).forEach(key => {
          if (pageRequest.filtersMap[key].value != null && pageRequest.filtersMap[key].value != "") {
            switch (pageRequest.filtersMap[key].matchMode) {
              case 'in': {
                if(pageRequest.filtersMap[key].value.length > 0) {
                  newExpression = builder.and(newExpression, builder.in(key, pageRequest.filtersMap[key].value));
                }

                break;
              }
              case 'startsWith': {
                newExpression = builder.and(newExpression, builder.eq(key, pageRequest.filtersMap[key].value + "*"));
                break;
              }

              case 'equals': {
                newExpression = builder.and(newExpression, builder.eq(key, pageRequest.filtersMap[key].value));
                break;
              }

              case 'contains': {
                newExpression = builder.and(newExpression, builder.comparison(key, "=ilike=", pageRequest.filtersMap[key].value));
                break;
              }

              case 'dateIs': {
                let date1 = new Date(pageRequest.filtersMap[key].value);
                date1.setHours(0, 0, 0, 0);
                let date2 = new Date(pageRequest.filtersMap[key].value);
                date2.setHours(23, 59, 59, 59);
                newExpression = builder.and(newExpression, builder.ge(key, date1.getTime()));
                newExpression = builder.and(newExpression, builder.le(key, date2.getTime()));
                break;
              }

              case 'dateBefore': {
                let date1 = new Date(pageRequest.filtersMap[key].value);
                date1.setHours(23, 59, 59, 59);
                newExpression = builder.and(newExpression, builder.le(key, date1.getTime()));
                break;
              }

              case 'dateAfter': {
                let date1 = new Date(pageRequest.filtersMap[key].value);
                date1.setHours(0, 0, 0, 0);
                newExpression = builder.and(newExpression, builder.ge(key, date1.getTime()));
                break;
              }
            }
            //newExpression = builder.and(newExpression, builder.eq(key, pageRequest.filtersMap[key].value));
          }
        });
        const rsql = emit(newExpression).substring(5);
        if(rsql) {
          params = params.set("filter", rsql);
        }
      }
    }

    return this._httpClient.get<ContentType>(url, {params: params});
  }

  post<ContentType> (url: string, content: ContentType | null): Observable<any> {
    return this._httpClient.post<void>(url, content);
  }

  put<ContentType> (url: string, content: ContentType): Observable<any> {
    return this._httpClient.put<any>(url, content);
  }

  patch<ContentType> (url: string, content: ContentType): Observable<void> {
    return this._httpClient.patch<void>(url, content);
  }

  delete(url: string): Observable<void> {
    return this._httpClient.delete<void>(url);
  }
}
