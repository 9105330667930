import {Component} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {MessageService} from "primeng/api";
import {UserService} from "../services/user.service";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../services/toast.service";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrl: './user.component.css',
  providers: [MessageService]
})
export class UserComponent {

  userForm = this.fb.group({
    email: [null, [Validators.required]],
    phoneNumber: [null, [Validators.required]],
    contactPerson: [null],
  });

  constructor(private userService: UserService,
              private config: DynamicDialogConfig,
              private fb: FormBuilder,
              private dialogRef: DynamicDialogRef,
              private toastService: ToastService) {

  }

  ngOnInit(): void {
    this.userService.getUser().subscribe(u => {
      this.userForm.patchValue(u);
    });

    this.email.disable();
  }

  get email() {
    return this.userForm.get('email');
  }

  get phoneNumber() {
    return this.userForm.get('phoneNumber');
  }

  get contactPerson() {
    return this.userForm.get('contactPerson');
  }

  submitForm() {
    this.userService.upsertUser(this.userForm.getRawValue()).subscribe({
      next: () => {
        this.toastService.setToast({
          'severity': 'success',
          'summary': 'Succes',
          'detail': 'Datele au fost modificate cu succes!'
        });
        this.dialogRef.close();
      },
      error: () => {
        this.toastService.setToast({
          'severity': 'error',
          'summary': 'Eroare',
          'detail': 'Datele nu au putut fi modificate!'
        });
      }
    });

  }
}
