import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {ToastService} from "../services/toast.service";
import {MessageService} from "primeng/api";

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.css',
  providers: [MessageService]
})
export class ToastComponent implements OnInit, OnDestroy{

  subscription: Subscription;

  constructor(private toastService: ToastService,
              private messageService: MessageService) {
  }


  ngOnInit() {
    this.subscribeToNotifications();
  }

  subscribeToNotifications() {
    this.subscription = this.toastService.toastChange
      .subscribe((toast: any) => {
        this.messageService.clear();
        if (toast) {
          this.messageService.add(toast);
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}
