import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Component, NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';

/**
 * InputGroup displays text, icon, buttons and other content can be grouped next to an input.
 * @group Components
 */
const _c0 = ["*"];
class InputGroup {
  static ɵfac = function InputGroup_Factory(t) {
    return new (t || InputGroup)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: InputGroup,
    selectors: [["p-inputGroup"]],
    hostAttrs: [1, "p-element", "p-inputgroup"],
    ngContentSelectors: _c0,
    decls: 2,
    vars: 1,
    consts: [[1, "p-inputgroup"]],
    template: function InputGroup_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵprojection(1);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵattribute("data-pc-name", "inputgroup");
      }
    },
    encapsulation: 2
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputGroup, [{
    type: Component,
    args: [{
      selector: 'p-inputGroup',
      template: `
        <div class="p-inputgroup" [attr.data-pc-name]="'inputgroup'">
            <ng-content></ng-content>
        </div>
    `,
      host: {
        class: 'p-element p-inputgroup'
      }
    }]
  }], null, null);
})();
class InputGroupModule {
  static ɵfac = function InputGroupModule_Factory(t) {
    return new (t || InputGroupModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: InputGroupModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputGroupModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [InputGroup, SharedModule],
      declarations: [InputGroup]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { InputGroup, InputGroupModule };
