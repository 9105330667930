import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {KeycloakAngularModule, KeycloakService} from "keycloak-angular";
import {initializer} from "../utils/app-init";
import { ViewUnitsComponent } from './view-units/view-units.component';
import { CreateUnitComponent } from './create-unit/create-unit.component';
import { UpdateUnitComponent } from './update-unit/update-unit.component';
import {TableModule} from "primeng/table";
import {LeafletMapComponent} from "./leaflet-map/leaflet-map.component";
import {DividerModule} from "primeng/divider";
import {ToastModule} from "primeng/toast";
import {LeafletModule} from "@asymmetrik/ngx-leaflet";
import { UnitFormComponent } from './unit-form/unit-form.component';
import { ValidationErrorComponent } from './validation-error/validation-error.component';
import { ToastComponent } from './toast/toast.component';
import {DropdownModule} from "primeng/dropdown";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {AutoCompleteModule} from "primeng/autocomplete";
import {InputGroupModule} from "primeng/inputgroup";
import { UserComponent } from './user/user.component';
import {AppTopBarComponent} from "./app.topbar.component";
import {CardModule} from "primeng/card";


@NgModule({
  declarations: [
    AppComponent,
    ViewUnitsComponent,
    CreateUnitComponent,
    UpdateUnitComponent,
    LeafletMapComponent,
    UnitFormComponent,
    ValidationErrorComponent,
    ToastComponent,
    UserComponent,
    AppTopBarComponent
  ],
  imports: [
    BrowserModule,
    KeycloakAngularModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CommonModule,
    FormsModule,
    InputTextModule,
    ButtonModule,
    RouterModule,
    TableModule,
    DividerModule,
    ToastModule,
    LeafletModule,
    DropdownModule,
    OverlayPanelModule,
    AutoCompleteModule,
    InputGroupModule,
    CardModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [KeycloakService],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
