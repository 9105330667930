export const environment = {
  production: false,
  apiUrl: '/api',
  keycloak: {
    // Keycloak url
    issuer: 'https://kyc-sso.laplace.rocks',
    // Realm
    realm: 'kyc',
    clientId: 'frontend'
  },
  productUrl: 'https://kyc-dev.laplace.rocks/',
  subscriptionUrl: 'https://billing.stripe.com/p/login/test_fZe8xmbrC5k42Z26oo'
};
