import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {RestService} from "./rest.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public baseUrl = `${environment.apiUrl}/user`

  constructor(private restService: RestService) {
  }

  getUser() {
    return this.restService.get<any>(this.baseUrl);
  }

  upsertUser(user: any) {
    return this.restService.post(this.baseUrl, user);
  }
}
