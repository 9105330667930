import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {Unit} from "../api/unit";
import {UnitService} from "../services/unit.service";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {ToastService} from "../services/toast.service";

@Component({
  selector: 'app-update-unit',
  templateUrl: './update-unit.component.html',
  styleUrl: './update-unit.component.css'
})
export class UpdateUnitComponent implements OnInit {
  updateUnitForm: FormGroup = this.fb.group({
    unit: [new Unit()]
  });

  constructor(private unitService: UnitService,
              private config: DynamicDialogConfig,
              private fb: FormBuilder,
              private dialogRef: DynamicDialogRef,
              private toastService: ToastService) {

  }

  get unit() {
    return this.updateUnitForm.get('unit');
  }

  ngOnInit(): void {
    this.unit?.patchValue(this.config.data.unit);
  }

  updateUnit() {
    if (this.updateUnitForm.invalid) {
      this.updateUnitForm.markAllAsTouched();
      return;
    }

    let unit = this.updateUnitForm.value.unit;
    if(unit.address.hasOwnProperty('displayName')) {
      unit.address = unit.address.displayName;
    }

    this.unitService.upsertUnit(unit).subscribe({
      next: () => {
        this.toastService.setToast({
          'severity': 'success',
          'summary': 'Succes',
          'detail': 'Unitatea a fost modificata cu succes!'
        });
        this.dialogRef.close();
      },
      error: () => {
        this.toastService.setToast({
          'severity': 'error',
          'summary': 'Eroare',
          'detail': 'Unitatea nu a putut fi modificata!'
        });
      }
    });
  }


}
